import { useEffect, useState } from "react";
import Header from "../../components/header";
import SideBar from "../../components/sidebar";
import Footer from "../../components/footer";
import { Link, useNavigate } from "react-router-dom";
import Axios from "axios";
import constant from "../../constant";
import { HashLink } from 'react-router-hash-link';
const Home = () => {
    const navigate = useNavigate()
    const [addressList, setAddressList] = useState()
    const [addressList2, setAddressList2] = useState()
    const [selectedaddressList, setselectedAddressList] = useState()
    const [selectedaddressList2, setselectedAddressList2] = useState()
    const [selectedPickup, setSelectedPickup] = useState()
    const [selectedDrop, setSelectedDrop] = useState()
    const [carType, setCarType] = useState()
    const [tripType, setTripType] = useState()
    const [pickupDate, setPickupDate] = useState()
    const [vacant, setVacant] = useState()
    const redirectButtonHandler = () =>{
        let data = {
            selectedDrop,
            selectedPickup,
            carType,
            tripType,
            pickupDate,
            vacant
        }
        localStorage.setItem('data', JSON.stringify(data))
        // navigate('/taxi#tax-area')
    }
    useEffect(() => {
        var element = document.querySelector("body");
        console.log(element)
        element.classList.add("home-3");
        return () => {
            element.classList.remove("home-3");
        }
    }, [])
   const GET_LOCATION_DETAILS = async(searchKey, type) => {
        
          try {
            let data = searchKey.replaceAll(' ','+')
            const response = await Axios.get(constant.BASE_URL+`/customer/getLocation?searchKey=${data}`);
            if(type == 'drop'){
                setAddressList2(response.data.features)

            }else {

                setAddressList(response.data.features)
            }
            //
         
          } catch (err) {
            console.log(err)
          }
       
      }
    return (<>
        <Header />
        <SideBar />
        <main className="main">

           
            <div class="hero-section">
                <div class="hero-single pb-70">
                    <div class="hero-shape">
                        <img src="/assets/img/shape/shape-9.png" />
                    </div>
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-md-12 col-lg-6">
                                <div class="hero-content">
                                    <h6 class="hero-sub-title wow fadeInUp" data-wow-delay=".25s">Welcome To
                                        cars53!</h6>
                                    <h1 class="hero-title wow fadeInRight" data-wow-delay=".50s">
                                        Your Ultimate  <span>Travel</span> Companion!
                                    </h1>
                                    <p class="wow fadeInLeft d-none d-lg-block" data-wow-delay=".75s">
                                        Are you tired of the stress and hassle of commuting? Look no further! Cars53 is here to
                                        revolutionize your travel experience. Founded in 2024 by the visionary Mr. Aziz Bootwala,
                                        Cars53 is dedicated to making commuting in Madhya Pradesh and Rajasthan stress-free,
                                        affordable, and convenient.
                                    </p>
                                    <div class="hero-btn wow fadeInUp d-none d-lg-block" data-wow-delay="1s">
                                        <Link to="/about" class="theme-btn">About Us<i class="fas fa-arrow-right"></i></Link>
                                        <Link to="/taxi" class="theme-btn theme-btn2">Book Now<i
                                            class="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 d-none d-lg-block">
                                <div class="hero-img wow fadeInRight" data-wow-delay=".25s">
                                    <img src="/assets/img/slider/01.jpg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="booking-area ">
                <div className="container">
                    <div className="booking-form">
                        <h4 className="booking-title">Book Your Ride</h4>

                        <div className="row">
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>Trip Type</label>
                                    <select value={tripType} onChange={(e)=>setTripType(e.target.value)} className="select-custom">
                                        <option value>Choose type</option>
                                        <option value="oneway">Oneway</option>
                                        <option value="twoway">Twoway</option>
                                        <option value="sharing">Sharing</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group" key={selectedPickup?.properties?.full_address}>
                                    <label>Pick Up Location</label>
                                    <input defaultValue={selectedPickup?.properties?.full_address} type="text" className="form-control" placeholder="Type Location" onChange={(e) => { e.target.value.length > 3 ? GET_LOCATION_DETAILS(e.target.value) : e.target.value.length < 1 && setAddressList() }} />
                                    <i className="far fa-location-dot"></i>
                                    {addressList?.length > 0 && <ul className="suggesting-dropdown">
                                        {addressList.map((item)=>{return (<><li onClick={() => { setSelectedPickup(item); setAddressList() }}>{item?.properties?.full_address}</li><hr/></>)})}
                                    </ul>}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group" key={selectedDrop?.properties?.full_address}>
                                    <label>Drop Off Location</label>
                                    <input defaultValue={selectedDrop?.properties?.full_address} type="text" className="form-control" placeholder="Type Location" onChange={(e) => { e.target.value.length > 3 ? GET_LOCATION_DETAILS(e.target.value, 'drop') : e.target.value.length < 1 && setAddressList2() }} />
                                    <i className="far fa-location-dot"></i>
                                    {addressList2?.length > 0 && <ul className="suggesting-dropdown">
                                        {addressList2.map((item)=>{return ( <><li onClick={() => { setSelectedDrop(item); setAddressList2() }}>{item?.properties?.full_address}</li><hr/></>)})}
                                    </ul>}
                                </div>
                            </div>

                            <div className="col-lg-4">
                                {tripType == 'sharing' ? <div className="form-group">
                                    <label>Enter # of passengers</label>
                                    <input value={vacant} type="text" className="form-control" placeholder="Type passenger count" onChange={(e) => { setVacant(e.target.value)}} />
                                    <i className="far fa-users"></i>
                                   
                                </div> : <div className="form-group">
                                    <label>Cab Type</label>
                                    <select value={carType} onChange={(e)=>setCarType(e.target.value)} className="select-custom">
                                        <option value>Choose Cab</option>
                                        <option value="all">All Type</option>
                                        <option value="hatchback">Hatchback</option>
                                        <option value="sedan">Sedan</option>
                                        <option value="muv">MUV</option>
                                        <option value="suv">SUV</option>
                                        <option value="minibus">Mini bus</option>
                                    </select>
                                </div>}
                            </div>
                            <div className="col-lg-4">
                                <div className="form-group">
                                    <label>Pick Up Date</label>
                                    <input value={pickupDate} onChange={(e)=>setPickupDate(e.target.value)} type="datetime-local" className="form-control" placeholder="MM/DD/YY" />
                                    <i className="far fa-calendar-days"></i>
                                </div>
                            </div>



                            <div className="col-lg-4 align-self-end">
                                <HashLink to={'/taxi#tax-area'} disabled={!selectedDrop?.properties?.full_address} onClick={()=>redirectButtonHandler()} className="theme-btn">Find Taxi<i
                                    className="fas fa-arrow-right"></i></HashLink>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div className="about-area py-120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-left wow fadeInLeft" data-wow-delay=".25s">
                                <div className="about-img">
                                    <img src="/assets/img/about/01.png" />
                                </div>
                                <div className="about-experience">
                                    <div className="about-experience-icon">
                                        <img src="/assets/img/icon/taxi-booking.svg" />
                                    </div>
                                    <b>Assured <br /> Quality Service</b>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-right wow fadeInRight" data-wow-delay=".25s">
                                <div className="site-heading mb-3">
                                    <span className="site-title-tagline justify-content-start">
                                        <i className="flaticon-drive"></i> About Us
                                    </span>
                                    <h2 className="site-title">
                                        We Provide Trusted <span>Cab Service</span> 
                                    </h2>
                                </div>
                                <p className="about-text">
                                    At Cars53, we understand the importance of quick availability. That's why our fleet of amazing
                                    cars and experienced drivers are always ready to pick you up whenever you need a ride.
                                    Whether you're heading to the airport, the office, or a night out on the town, Cars53 has you
                                    covered.
                                </p>
                                <div className="about-list-wrapper">
                                    <ul className="about-list list-unstyled">
                                        <li>
                                            Quick Availability
                                        </li>
                                        <li>
                                            Affordable Pricing
                                        </li>
                                        <li>
                                            Top-Notch Service
                                        </li>
                                    </ul>
                                </div>
                                <Link to="/about" className="theme-btn mt-4">Discover More<i
                                    className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="service-area bg py-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="site-heading text-center">
                                <span className="site-title-tagline">Services</span>
                                <h2 className="site-title">Our Best Services For You</h2>
                                <div className="heading-divider"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="service-item wow fadeInUp" data-wow-delay=".25s">
                                <div className="service-img">
                                    <img src="/assets/img/service/01.jpg" />
                                </div>
                                <div className="service-icon">
                                    <img src="/assets/img/icon/taxi-booking-1.svg" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="#">Online Booking</a>
                                    </h3>
                                    <p className="service-text">
                                        Effortlessly hail rides anytime, anywhere with our streamlined online cab booking service. Enjoy convenience, and peace of mind as you travel to your destination with just a few clicks.
                                    </p>
                                    <div className="service-arrow">
                                        <Link to="/taxi" className="theme-btn">Book Now<i className="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="service-item wow fadeInUp" data-wow-delay=".50s">
                                <div className="service-img">
                                    <img src="/assets/img/service/02.jpg" />
                                </div>
                                <div className="service-icon">
                                    <img src="/assets/img/icon/city-taxi.svg" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="#">One way Transport</a>
                                    </h3>
                                    <p className="service-text">
                                        Experience the ease of hassle-free travel with our convenient one-way transport service. Say goodbye to worries and hello to a smooth journey, every time.
                                    </p>
                                    <div className="service-arrow">
                                        <Link to="/taxi" className="theme-btn">Book Now<i className="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="service-item wow fadeInUp" data-wow-delay=".75s">
                                <div className="service-img">
                                    <img src="/assets/img/service/03.jpg" />
                                </div>
                                <div className="service-icon">
                                    <img src="/assets/img/icon/airport.svg" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="#">Airport Transport</a>
                                    </h3>
                                    <p className="service-text">
                                        Experience stress-free travel with our reliable airport transport service. From touchdown to takeoff, we've got your journey covered with punctuality, comfort, and convenience.
                                    </p>
                                    <div className="service-arrow">
                                        <Link to="/taxi" className="theme-btn">Book Now<i className="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="service-item wow fadeInUp" data-wow-delay=".25s">
                                <div className="service-img">
                                    <img src="/assets/img/service/04.jpg" />
                                </div>
                                <div className="service-icon">
                                    <img src="/assets/img/icon/business.svg" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="#">Roundtrip Transport</a>
                                    </h3>
                                    <p className="service-text">
                                        Experience the ultimate in convenience with our roundtrip transport service. Enjoy seamless travel to your destination and back, with reliability and comfort guaranteed every step of the way.
                                    </p>
                                    <div className="service-arrow">
                                        <Link to="/taxi" className="theme-btn">Book Now<i className="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="service-item wow fadeInUp" data-wow-delay=".50s">
                                <div className="service-img">
                                    <img src="/assets/img/service/05.jpg" />
                                </div>
                                <div className="service-icon">
                                    <img src="/assets/img/icon/taxi-2.svg" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="#">Sharing Transport</a>
                                    </h3>
                                    <p className="service-text">
                                        Easily share rides and split costs with our innovative taxi pooling service. Reduce your carbon footprint while enjoying a cost-effective and efficient way to travel, all with the convenience of our user-friendly platform.
                                    </p>
                                    <div className="service-arrow">
                                        <Link to="/taxi" className="theme-btn">Book Now<i className="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="service-item wow fadeInUp" data-wow-delay=".75s">
                                <div className="service-img">
                                    <img src="/assets/img/service/06.jpg" />
                                </div>
                                <div className="service-icon">
                                    <img src="/assets/img/icon/taxi.svg" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="#">Tour Transport</a>
                                    </h3>
                                    <p className="service-text">
                                        Embark on unforgettable journeys with our tour wandering transport service. Explore new destinations with comfort, as our expert drivers guide you through breathtaking sights and creating memories that last a lifetime
                                    </p>
                                    <div className="service-arrow">
                                        <Link to="/taxi" className="theme-btn">Book Now<i className="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className="counter-area">
                <div className="container">
                    <div className="counter-wrapper">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-box">
                                    <div className="icon">
                                        <img src="/assets/img/icon/taxi-1.svg" />
                                    </div>
                                    <div>
                                        {/* <span className="counter" data-count="+" data-to="500" data-speed="3000">500</span> */}
                                        <h3 style={{fontSize:'30px'}} className="title">Clean Taxi </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-box">
                                    <div className="icon">
                                        <img src="/assets/img/icon/happy.svg" />
                                    </div>
                                    <div>
                                        {/* <span className="counter" data-count="+" data-to="900" data-speed="3000">900</span> */}
                                        <h3 style={{fontSize:'30px'}} className="title">Prompt Support </h3>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-box">
                                    <div className="icon">
                                        <img src="/assets/img/icon/driver.svg" />
                                    </div>
                                    <div>
                                    <h3 style={{fontSize:'30px'}} className="title">Verified Drivers </h3>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-box">
                                    <div className="icon">
                                        <img src="/assets/img/icon/trip.svg" />
                                    </div>
                                    <div>
                                    <h3 style={{fontSize:'30px'}} className="title">Ontime Service</h3>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="feature-area feature-bg py-120">
                <div className="container mt-150">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="site-heading text-center">
                                <span className="site-title-tagline">Feature</span>
                                <h2 className="site-title text-white">Our Awesome Feature</h2>
                                <div className="heading-divider"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <div className="feature-item wow fadeInUp" data-wow-delay=".25s">
                                <div className="feature-icon">
                                    <img src="/assets/img/icon/taxi-safety.svg" />
                                </div>
                                <div className="feature-content">
                                    <h4>Safety Guarantee</h4>
                                    <p>Your safety is priority. With stringent driver vetting, real-time tracking, and 24/7 support and worry-free ride every time you book through our taxi app</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="feature-item mt-lg-5 wow fadeInDown" data-wow-delay=".25s">
                                <div className="feature-icon">
                                    <img src="/assets/img/icon/pickup.svg" />
                                </div>
                                <div className="feature-content">
                                    <h4>Fast Pickup</h4>
                                    <p>Get where you need to be in no time with our quick booking app. Say goodbye to waiting and hello to seamless travel at your fingertips.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="feature-item wow fadeInUp" data-wow-delay=".25s">
                                <div className="feature-icon">
                                    <img src="/assets/img/icon/money.svg" />
                                </div>
                                <div className="feature-content">
                                    <h4>Affordable Rate</h4>
                                    <p> Get to your destination conveniently and economically with our affordable rates, ensuring a smooth ride every time.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="feature-item mt-lg-5 wow fadeInDown" data-wow-delay=".25s">
                                <div className="feature-icon">
                                    <img src="/assets/img/icon/support.svg" />
                                </div>
                                <div className="feature-content">
                                    <h4>24/7 Support</h4>
                                    <p>Enjoy peace of mind with our 24/7 support for your taxi bookings. Ensuring a seamless experience from start to finish.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="taxi-rate py-120 d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="site-heading text-center">
                                <span className="site-title-tagline">Packages</span>
                                <h2 className="site-title">Our Taxi Packages For You</h2>
                                <div className="heading-divider"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="rate-item wow fadeInUp" data-wow-delay=".25s">
                                <div className="rate-header">
                                    <div className="rate-img">
                                        <img src="/assets/img/rate/01.png" />
                                    </div>
                                </div>
                                <div className="rate-header-content">
                                    <h4>Basic Pakage</h4>
                                    <p className="rate-duration">One Time Payment</p>
                                </div>
                                <div className="rate-content">
                                    <div className="rate-icon">
                                        <img src="/assets/img/icon/taxi-1.svg" />
                                    </div>
                                    <div className="rate-feature">
                                        <ul>
                                            <li><i className="far fa-check-double"></i> Base Charge: <span>$2.30</span></li>
                                            <li><i className="far fa-check-double"></i> Distance Allowance: <span>5000m</span>
                                            </li>
                                            <li><i className="far fa-check-double"></i> Up To 50 kms: <span>$1.38/km</span></li>
                                            <li><i className="far fa-check-double"></i> Booking Fee: <span>$0.99</span></li>
                                            <li><i className="far fa-check-double"></i> Extra Passangers: <span>$0.45</span>
                                            </li>
                                        </ul>
                                        <a href="#" className="theme-btn">Choose Plan<i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="rate-item wow fadeInDown" data-wow-delay=".25s">
                                <div className="rate-header">
                                    <div className="rate-img">
                                        <img src="/assets/img/rate/01.png" />
                                    </div>
                                </div>
                                <div className="rate-header-content">
                                    <h4>Standard Pakage</h4>
                                    <p className="rate-duration">One Time Payment</p>
                                </div>
                                <div className="rate-content">
                                    <div className="rate-icon">
                                        <img src="/assets/img/icon/taxi-1.svg" />
                                    </div>
                                    <div className="rate-feature">
                                        <ul>
                                            <li><i className="far fa-check-double"></i> Base Charge: <span>$2.30</span></li>
                                            <li><i className="far fa-check-double"></i> Distance Allowance: <span>5000m</span>
                                            </li>
                                            <li><i className="far fa-check-double"></i> Up To 50 kms: <span>$1.38/km</span></li>
                                            <li><i className="far fa-check-double"></i> Booking Fee: <span>$0.99</span></li>
                                            <li><i className="far fa-check-double"></i> Extra Passangers: <span>$0.45</span>
                                            </li>
                                        </ul>
                                        <a href="#" className="theme-btn">Choose Plan<i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="rate-item wow fadeInUp" data-wow-delay=".25s">
                                <div className="rate-header">
                                    <div className="rate-img">
                                        <img src="/assets/img/rate/01.png" />
                                    </div>
                                </div>
                                <div className="rate-header-content">
                                    <h4>Premium Pakage</h4>
                                    <p className="rate-duration">One Time Payment</p>
                                </div>
                                <div className="rate-content">
                                    <div className="rate-icon">
                                        <img src="/assets/img/icon/taxi-1.svg" />
                                    </div>
                                    <div className="rate-feature">
                                        <ul>
                                            <li><i className="far fa-check-double"></i> Base Charge: <span>$2.30</span></li>
                                            <li><i className="far fa-check-double"></i> Distance Allowance: <span>5000m</span>
                                            </li>
                                            <li><i className="far fa-check-double"></i> Up To 50 kms: <span>$1.38/km</span></li>
                                            <li><i className="far fa-check-double"></i> Booking Fee: <span>$0.99</span></li>
                                            <li><i className="far fa-check-double"></i> Extra Passangers: <span>$0.45</span>
                                            </li>
                                        </ul>
                                        <a href="#" className="theme-btn">Choose Plan<i className="fas fa-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="team-area pb-120 d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="site-heading text-center">
                                <span className="site-title-tagline">Drivers</span>
                                <h2 className="site-title">Our Expert Drivers</h2>
                                <div className="heading-divider"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <div className="team-item wow fadeInUp" data-wow-delay=".25s">
                                <div className="team-img">
                                    <img src="/assets/img/team/01.jpg" alt="thumb" />
                                </div>
                                <div className="team-content">
                                    <div className="team-bio">
                                        <h5><a href="#">Alma Mcelroy</a></h5>
                                        <span>Expert Driver</span>
                                    </div>
                                </div>
                                <div className="team-social">
                                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                    <a href="#"><i className="fab fa-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="team-item wow fadeInDown" data-wow-delay=".25s">
                                <div className="team-img">
                                    <img src="/assets/img/team/02.jpg" alt="thumb" />
                                </div>
                                <div className="team-content">
                                    <div className="team-bio">
                                        <h5><a href="#">Marie Hooks</a></h5>
                                        <span>Expert Driver</span>
                                    </div>
                                </div>
                                <div className="team-social">
                                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                    <a href="#"><i className="fab fa-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="team-item wow fadeInUp" data-wow-delay=".25s">
                                <div className="team-img">
                                    <img src="/assets/img/team/03.jpg" alt="thumb" />
                                </div>
                                <div className="team-content">
                                    <div className="team-bio">
                                        <h5><a href="#">Daniel Nesmith</a></h5>
                                        <span>Expert Driver</span>
                                    </div>
                                </div>
                                <div className="team-social">
                                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                    <a href="#"><i className="fab fa-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="team-item wow fadeInDown" data-wow-delay=".25s">
                                <div className="team-img">
                                    <img src="/assets/img/team/04.jpg" alt="thumb" />
                                </div>
                                <div className="team-content">
                                    <div className="team-bio">
                                        <h5><a href="#">Gertrude Barrow</a></h5>
                                        <span>Expert Driver</span>
                                    </div>
                                </div>
                                <div className="team-social">
                                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                    <a href="#"><i className="fab fa-youtube"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="video-area vda-2">
                <div className="container">
                    <div className="video-content" style={{ backgroundImage: `url(/assets/img/video/01.jpg)` }}>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="video-wrapper">
                                    <a className="play-btn popup-youtube" href="https://www.youtube.com/watch?v=ckHzmP1evNU">
                                        <i className="fas fa-play"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="choose-area cha-2 py-120">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="choose-content">
                                <div className="site-heading wow fadeInDown mb-4" data-wow-delay=".25s">
                                    <span className="site-title-tagline text-white justify-content-start">
                                        <i className="flaticon-drive"></i> Why Choose Us
                                    </span>
                                    <h2 className="site-title text-white mb-10">We are dedicated <span>to provide</span> quality
                                        service</h2>
                                    <p className="text-white">
                                        We are committed to delivering exceptional service, ensuring your experience is nothing short of excellent. With a focus on quality at every step, we strive to exceed your expectations and leave you completely satisfied with our offerings.
                                    </p>
                                </div>
                                <div className="choose-img wow fadeInUp" data-wow-delay=".25s">
                                    <img src="/assets/img/choose/01.png" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="choose-content-wrapper wow fadeInRight" data-wow-delay=".25s">
                                <div className="choose-item">
                                    <span className="choose-count">01</span>
                                    <div className="choose-item-icon">
                                        <img src="/assets/img/icon/taxi-1.svg" />
                                    </div>
                                    <div className="choose-item-info">
                                        <h3>Best Quality Taxi</h3>
                                        <p>Experience the epitome of excellence with our top-tier taxi service. From pristine vehicles to professional drivers, we're dedicated to delivering the best quality travel experience, every time.</p>
                                    </div>
                                </div>
                                <div className="choose-item ms-lg-5">
                                    <span className="choose-count">02</span>
                                    <div className="choose-item-icon">
                                        <img src="/assets/img/icon/driver.svg" />
                                    </div>
                                    <div className="choose-item-info">
                                        <h3>Expert Drivers</h3>
                                        <p>Our expert drivers ensure your journey is not just safe, but also comfortable and efficient. With their skill and professionalism, you can relax and enjoy the ride with complete peace of mind.</p>
                                    </div>
                                </div>
                                <div className="choose-item mb-lg-0">
                                    <span className="choose-count">03</span>
                                    <div className="choose-item-icon">
                                        <img src="/assets/img/icon/taxi-location.svg" />
                                    </div>
                                    <div className="choose-item-info">
                                        <h3>Many Locations</h3>
                                        <p>Accessible across multiple locations, our service ensures convenience no matter where you are. From bustling cities to remote destinations, count on us to be there for all your transportation needs.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="faq-area py-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="faq-right">
                                <div className="site-heading mb-3">
                                    <span className="site-title-tagline justify-content-start">Faq's</span>
                                    <h2 className="site-title my-3">Have  <span>Questions?</span> We've Got Answers!</h2>
                                </div>
                                <p className="about-text">At Cars53, we want to make sure that you have all the information you need to travel with
                                    confidence. That's why we've put together this list of frequently asked questions to help address
                                    any concerns you may have.</p>
                                <div className="faq-img mt-3">
                                    <img src="/assets/img/faq/01.jpg" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <span><i className="far fa-question"></i></span> How do I book a ride with Cars53?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Booking a ride with Cars53 is quick and easy! Simply download our app, enter your pick-up
                                            and drop-off locations, choose your ride option, and confirm your booking. Your ride will arrive
                                            promptly to take you to your destination.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <span><i className="far fa-question"></i></span>What types of rides does Cars53 offer?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Cars53 offers a range of ride options to suit your needs and preferences. Whether you're
                                            traveling solo, with family, or in a group, we have the perfect ride for you. Choose from standard
                                            cars, SUVs, and more.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree" aria-expanded="false"
                                            aria-controls="collapseThree">
                                            <span><i className="far fa-question"></i></span> How much does it cost to ride with Cars53?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse"
                                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Our pricing is competitive and affordable, so you can enjoy the luxury of traveling in style
                                            without breaking the bank. Prices vary depending on factors like distance, time of day, and ride
                                            option selected.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour" aria-expanded="false"
                                            aria-controls="collapseFour">
                                            <span><i className="far fa-question"></i></span> Are Cars53 drivers experienced and licensed?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Yes, all Cars53 drivers are experienced, licensed, and thoroughly vetted to ensure your
                                            safety and comfort. They are committed to providing you with a professional and enjoyable ride
                                            every time you travel with us.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="testimonial-area py-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="site-heading text-center">
                                <span className="site-title-tagline"><i className="flaticon-drive"></i> Testimonials</span>
                                <h2 className="site-title text-white">What Our Client <span>Say's</span></h2>
                                <div className="heading-divider"></div>
                            </div>
                        </div>
                    </div>
                    <div className="testimonial-slider owl-carousel owl-theme">
                        <div className="testimonial-single">
                            <div className="testimonial-content">
                                <div className="testimonial-author-img">
                                    <img src="/assets/img/testimonial/01.jpg" />
                                </div>
                                <div className="testimonial-author-info">
                                    <h4>Sylvia Green</h4>
                                    <p>Customer</p>
                                </div>
                            </div>
                            <div className="testimonial-quote">
                                <span className="testimonial-quote-icon"><i className="far fa-quote-right"></i></span>
                                <p>
                                    There are many variations of words suffered available to the have majority but the
                                    majority
                                    suffer to alteration injected hidden the middle text.
                                </p>
                            </div>
                            <div className="testimonial-rate">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                            </div>
                        </div>
                        <div className="testimonial-single">
                            <div className="testimonial-content">
                                <div className="testimonial-author-img">
                                    <img src="/assets/img/testimonial/02.jpg" />
                                </div>
                                <div className="testimonial-author-info">
                                    <h4>Gordo Novak</h4>
                                    <p>Customer</p>
                                </div>
                            </div>
                            <div className="testimonial-quote">
                                <span className="testimonial-quote-icon"><i className="far fa-quote-right"></i></span>
                                <p>
                                    There are many variations of words suffered available to the have majority but the
                                    majority
                                    suffer to alteration injected hidden the middle text.
                                </p>
                            </div>
                            <div className="testimonial-rate">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                            </div>
                        </div>
                        <div className="testimonial-single">
                            <div className="testimonial-content">
                                <div className="testimonial-author-img">
                                    <img src="/assets/img/testimonial/03.jpg" />
                                </div>
                                <div className="testimonial-author-info">
                                    <h4>Reid Butt</h4>
                                    <p>Customer</p>
                                </div>
                            </div>
                            <div className="testimonial-quote">
                                <span className="testimonial-quote-icon"><i className="far fa-quote-right"></i></span>
                                <p>
                                    There are many variations of words suffered available to the have majority but the
                                    majority
                                    suffer to alteration injected hidden the middle text.
                                </p>
                            </div>
                            <div className="testimonial-rate">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                            </div>
                        </div>
                        <div className="testimonial-single">
                            <div className="testimonial-content">
                                <div className="testimonial-author-img">
                                    <img src="/assets/img/testimonial/04.jpg" />
                                </div>
                                <div className="testimonial-author-info">
                                    <h4>Parker Jime</h4>
                                    <p>Customer</p>
                                </div>
                            </div>
                            <div className="testimonial-quote">
                                <span className="testimonial-quote-icon"><i className="far fa-quote-right"></i></span>
                                <p>
                                    There are many variations of words suffered available to the have majority but the
                                    majority
                                    suffer to alteration injected hidden the middle text.
                                </p>
                            </div>
                            <div className="testimonial-rate">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                            </div>
                        </div>
                        <div className="testimonial-single">
                            <div className="testimonial-content">
                                <div className="testimonial-author-img">
                                    <img src="/assets/img/testimonial/05.jpg" />
                                </div>
                                <div className="testimonial-author-info">
                                    <h4>Heruli Nez</h4>
                                    <p>Customer</p>
                                </div>
                            </div>
                            <div className="testimonial-quote">
                                <span className="testimonial-quote-icon"><i className="far fa-quote-right"></i></span>
                                <p>
                                    There are many variations of words suffered available to the have majority but the
                                    majority
                                    suffer to alteration injected hidden the middle text.
                                </p>
                            </div>
                            <div className="testimonial-rate">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="cta-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 text-center text-lg-start">
                            <div className="cta-text cta-divider">
                                <h1>Book Your Cab It's Simple And Affordable</h1>
                                <p>It is a long established fact that a reader will be distracted by the readable content of
                                    a page when looking at its layout point of using is that it has normal distribution of
                                    letters.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 text-center text-lg-end">
                            <div className="mb-20">
                                <a href="#" className="cta-number"><i className="far fa-headset"></i>+91 94795 25453</a>
                            </div>
                            <div className="cta-btn">
                                <Link to="/taxi" className="theme-btn">Book Your Cab<i className="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="blog-area pt-120 d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="site-heading text-center">
                                <span className="site-title-tagline"><i className="flaticon-drive"></i> Our Blog</span>
                                <h2 className="site-title">Latest News & Blog</h2>
                                <div className="heading-divider"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="blog-item wow fadeInUp" data-wow-delay=".25s">
                                <div className="blog-item-img">
                                    <img src="/assets/img/blog/01.jpg" alt="Thumb" />
                                </div>
                                <div className="blog-item-info">
                                    <div className="blog-item-meta">
                                        <ul>
                                            <li><a href="#"><i className="far fa-user-circle"></i> By Alicia Davis</a></li>
                                            <li><a href="#"><i className="far fa-calendar-alt"></i> February 23, 2023</a></li>
                                        </ul>
                                    </div>
                                    <h4 className="blog-title">
                                        <a href="#">There are many variations of passage available.</a>
                                    </h4>
                                    <a className="theme-btn" href="#">Read More<i className="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="blog-item wow fadeInUp" data-wow-delay=".50s">
                                <div className="blog-item-img">
                                    <img src="/assets/img/blog/02.jpg" alt="Thumb" />
                                </div>
                                <div className="blog-item-info">
                                    <div className="blog-item-meta">
                                        <ul>
                                            <li><a href="#"><i className="far fa-user-circle"></i> By Alicia Davis</a></li>
                                            <li><a href="#"><i className="far fa-calendar-alt"></i> February 23, 2023</a></li>
                                        </ul>
                                    </div>
                                    <h4 className="blog-title">
                                        <a href="#">There are many variations of passage available.</a>
                                    </h4>
                                    <a className="theme-btn" href="#">Read More<i className="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="blog-item wow fadeInUp" data-wow-delay=".75s">
                                <div className="blog-item-img">
                                    <img src="/assets/img/blog/03.jpg" alt="Thumb" />
                                </div>
                                <div className="blog-item-info">
                                    <div className="blog-item-meta">
                                        <ul>
                                            <li><a href="#"><i className="far fa-user-circle"></i> By Alicia Davis</a></li>
                                            <li><a href="#"><i className="far fa-calendar-alt"></i> February 23, 2023</a></li>
                                        </ul>
                                    </div>
                                    <h4 className="blog-title">
                                        <a href="#">There are many variations of passage available.</a>
                                    </h4>
                                    <a className="theme-btn" href="#">Read More<i className="fas fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="partner pt-80 pb-80">
                <div className="container">
                    <div className="partner-slider owl-carousel owl-theme">
                        <div className="partner-item">
                            <div className="partner-img">
                                <img src="/assets/img/partner/01.png" />
                            </div>
                        </div>
                        <div className="partner-item">
                            <div className="partner-img">
                                <img src="/assets/img/partner/02.png" />
                            </div>
                        </div>
                        <div className="partner-item">
                            <div className="partner-img">
                                <img src="/assets/img/partner/03.png" />
                            </div>
                        </div>
                        <div className="partner-item">
                            <div className="partner-img">
                                <img src="/assets/img/partner/01.png" />
                            </div>
                        </div>
                        <div className="partner-item">
                            <div className="partner-img">
                                <img src="/assets/img/partner/02.png" />
                            </div>
                        </div>
                        <div className="partner-item">
                            <div className="partner-img">
                                <img src="/assets/img/partner/03.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="download-area mb-120">
                <div className="container">
                    <div className="download-wrapper">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="download-content">
                                    <div className="site-heading mb-4">
                                        <span className="site-title-tagline justify-content-start">
                                            <i className="flaticon-drive"></i> Get Our App
                                        </span>
                                        <h2 className="site-title mb-10">Download <span>Our cars53</span> App For Free</h2>
                                        <p>
                                        Unlock seamless transportation at your fingertips - download our Cars53 app for free today! Enjoy easy booking, real-time tracking, and exclusive deals, all in one user-friendly platform.
                                        </p>
                                    </div>
                                    <div className="download-btn">
                                        <a href="https://play.google.com/store/apps/details?id=com.cars53.user">
                                            <i className="fab fa-google-play"></i>
                                            <div className="download-btn-content">
                                                <span>Get It On</span>
                                                <strong>Google Play</strong>
                                            </div>
                                        </a>
                                        <a href="#">
                                            <i className="fab fa-app-store"></i>
                                            <div className="download-btn-content">
                                                <span>Get It On</span>
                                                <strong>App Store</strong>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="download-img">
                            <img src="/assets/img/download/01.png" />
                        </div>
                    </div>
                </div>
            </div>

        </main>

        <Footer />
    </>
    )
}
export default Home;