import { Link, useLocation } from 'react-router-dom';
import './MobileFooter.css'
import { useEffect } from 'react';
const MobileFooter = () => {
    const currentRoute = window.location.pathname;
    let location = useLocation();
    
  
      useEffect(() => {
      console.log(location.pathname);
    }, []);
    return (
<nav className='mobile-footer d-block d-md-none'>
  <div class="nav-box">
    <ul class="nav-container">
      <li class={`nav__item ${currentRoute == '/' && 'active'}`}>
        <Link to="/" class="nav__item-link">
          <div class="nav__item-icon">
            <ion-icon name="home-outline"></ion-icon>
          </div>
          <span class="nav__item-text">Home</span>
        </Link>
      </li>
      <li class={`nav__item ${currentRoute == '/mybookings' && 'active'}`}>
        <Link to="/mybookings" class="nav__item-link">
          <div class="nav__item-icon">
            <ion-icon name="reader-outline"></ion-icon>
          </div>
          <span class="nav__item-text">Bookings</span>
        </Link>
      </li>
      <li class={`nav__item ${currentRoute == '/dashboard' && 'active'}`}>
        <Link to="/dashboard" class="nav__item-link">
          <div class="nav__item-icon">
            <ion-icon name="compass-outline"></ion-icon>
          </div>
          <span class="nav__item-text">Dashboard</span>
        </Link>
      </li>
      <li class={`nav__item ${currentRoute == '/payments' && 'active'}`}>
        <Link to="/payments" class="nav__item-link">
          <div class="nav__item-icon">
          <ion-icon name="cash-outline"></ion-icon>
          </div>
          <span class="nav__item-text">Payments</span>
        </Link>
      </li>
      
      <li class={`nav__item ${currentRoute == '/myprofile' && 'active'}`}>
        <Link to="/myprofile" class="nav__item-link">
          <div class="nav__item-icon">
          <ion-icon name="person-outline"></ion-icon>
          </div>
          <span class="nav__item-text">Profile</span>
        </Link>
      </li>
    </ul>
  </div>
</nav>
    )
}
export default MobileFooter