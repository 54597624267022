import { Link, useNavigate } from "react-router-dom"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"
import { useEffect, useState } from "react"
import constant from "../../constant"
import services from "../../services"
import Toaster from "../../components/toaster";
function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}
const BookNow = () => {
    const navigate = useNavigate()
    const [bookingData, setBookingData] = useState()
    const [perKmCost, setPerKmCost] = useState()
    const [isAcAvailable, setisAcAvailable] = useState(false)
    const [isCarrierAvailable, setisCarrierAvailable] = useState(false)
    const [userDetails, setUserDetails] = useState()
    const [endDate, setEndDate] = useState()
    const [toaster, setToaster] = useState({ visiblity: "hide" });
    const [name, setName] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [email, setEmail] = useState()
    const [customerNote, setCustomerNote] = useState()
    const [passengerCount, setPassengerCount] = useState()
    useEffect(() => {
        let data = localStorage.getItem('data');
        if (data) {
            setBookingData(JSON.parse(data))
            if(JSON.parse(data)?.item.Car){
                setPerKmCost(JSON.parse(data)?.item?.perKmCharge)
            } else {
                setPerKmCost(JSON.parse(data)?.item?.perKmCharge)
            }
            if(JSON.parse(data)?.vacant){
                setPassengerCount(JSON.parse(data)?.vacant)
            }
            let userData = JSON.parse(localStorage.getItem('userDetails'))
            setUserDetails(userData)
            setName(userData.firstName)
            setPhoneNumber(userData.phoneNumber)
            setEmail(userData.email)
        } else {
            navigate('/taxi')
        }

    }, [])
    useEffect(()=>{
        if(bookingData && isAcAvailable){
            if(bookingData?.item.Car){
                setPerKmCost(bookingData?.item?.perKmCharge+1)
            } else {
                setPerKmCost(bookingData?.item?.perKmCharge+1)
            }
        } else if(bookingData) {
            if(bookingData?.item.Car){
                setPerKmCost(bookingData?.item?.perKmCharge)
            } else {
                setPerKmCost(bookingData?.item?.perKmCharge)
            } 
        }
    },[isAcAvailable])
    const requestBookingHandler = async () => {
        try {
            let payload = {
                pickupLocation: { lat: bookingData.selectedPickup.geometry.coordinates[1] + '', lng: bookingData.selectedPickup.geometry.coordinates[0] + '', addressLine: bookingData.selectedPickup?.properties?.full_address },
                dropLocation: { lat: bookingData.selectedDrop.geometry.coordinates[1] + '', lng: bookingData.selectedDrop.geometry.coordinates[0] + '', addressLine: bookingData.selectedDrop?.properties?.full_address },
                tripType: bookingData.tripType,
                tripStartDate: bookingData.pickupDate,
                tripEndDate: endDate,
                carId: bookingData.item.id,
                customerNote,
                passengerCount,
                isAcAvailable,
                isCarrierAvailable,
                customerData: {
                    name,
                    email,
                    phoneNumber
                }
            }
            console.log(payload)
            const response = await services.order.REQUEST_BOOKING(payload);
            console.log(response)
            if (response) {
                localStorage.removeItem('data')
                navigate('/mybookings')
            }
        } catch (error) {
            console.log(error)
        }
    }
    const requestVendorBooking = async () => {
        try {
            let payload = {
                pickupLocation: { lat: bookingData.selectedPickup.geometry.coordinates[1] + '', lng: bookingData.selectedPickup.geometry.coordinates[0] + '', addressLine: bookingData.selectedPickup?.properties?.full_address },
                dropLocation: { lat: bookingData.selectedDrop.geometry.coordinates[1] + '', lng: bookingData.selectedDrop.geometry.coordinates[0] + '', addressLine: bookingData.selectedDrop?.properties?.full_address },
                tripType: bookingData.tripType,
                tripStartDate: bookingData.pickupDate,
                tripEndDate: endDate,
                vendorCarId: bookingData.item.id,
                customerNote,
                passengerCount,
                isAcAvailable,
                isCarrierAvailable,
                customerData: {
                    name,
                    email,
                    phoneNumber
                }
            }
            console.log(payload)
            const response = await services.order.REQUEST_VENDOR_BOOKING(payload);
            console.log(response)
            if (response) {
                localStorage.removeItem('data')
                navigate('/mybookings')
            }
        } catch (error) {
            console.log(error)
        }
    }
    const requestPaymentHandler = async () => {
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
        try {
            let total = ((((isAcAvailable ? bookingData.item.carData.perKmCharge+1 : bookingData.item.carData.perKmCharge) * (bookingData.item.distanceBreakdown.distance / 1000)) / ((bookingData.item.carData.customerCapacity - bookingData.item.sharingAvailable) + (+bookingData.vacant))) * bookingData.vacant);
            let commissionp = bookingData.item.vendorData.VendorDetail.sharingCommission;
            let commission = ((total * commissionp) / 100)?.toFixed(0)
            let data = {
                amount: +commission * 100
            }
            let oldBookings = bookingData.item.BookingDetails.map((item) => {
                return {
                    id: item.id,
                    data: {
                        amountBreakdown: {
                            commissionPaid: item.BookingPayment?.BookingPaymentDetails[0].amount / 100,
                            remainingAmount: (total / bookingData.vacant) * item.passengerCount,
                        }
                    }
                }
            })
            console.log(oldBookings)
            const paymentrequest = await services.order.REQUEST_OPEN_PAYMENT(data);
            const options = {
                key: "rzp_live_X9AC4dOucZtKRc",
                currency: "INR",
                amount: +commission * 100,
                order_id: paymentrequest?.data?.response?.id,
                name: "Cars53",
                description: "Thank you for ordering. Please initiate payment!",
                image:
                    "",
                modal: {
                    ondismiss: async () => {
                        // const data = {
                        //     paymentDetailId: paymentrequest.data.bookingPaymentDetail.id,
                        //     paymentStatus: "failure",
                        //     transactionData: {}
                        // };
                        // const response = await services.order.COMPLETE_PAYMENT(data);
                        setToaster({
                            type: "danger",
                            title: "Payment Failed",
                            text: 'Payment Failed',
                            visiblity: "show",
                        });
                        setTimeout(() => {
                            setToaster({
                                type: "danger",
                                title: "Error",
                                text: 'Error',
                                visiblity: "hide",
                            });
                        }, 1500)
                        // getBookingsHandler()
                    },
                },
                handler: function (response) {
                    (async () => {
                        const data = {
                            oldBookings: oldBookings,
                            paymentData: {
                                amountBreakdown: {
                                    commissionPaid: commission,
                                    remainingAmount: total
                                }, transactionData: response, amount: +commission * 100
                            },
                            newBooking: {
                                amountBreakdown: {
                                    commissionPaid: commission,
                                    remainingAmount: total
                                },


                                passengerCount: bookingData.vacant,

                                customerNote: customerNote,
                                customerData: {
                                    name,
                                    email,
                                    phoneNumber
                                },
                                bookingId: bookingData.item.id

                            },
                            bookingData : {
                                sharingAvailable : (bookingData.item.carData.customerCapacity - ((bookingData.item.carData.customerCapacity - bookingData.item.sharingAvailable) + (+bookingData.vacant)))
                            }

                        };
                        const responsesuccess = await services.order.CREATE_SHARING_BOOKING(data);

                        setToaster({
                            type: "success",
                            title: "Payment Done!",
                            text: 'Payment Done successfully!',
                            visiblity: "show",
                        });
                        setTimeout(() => {
                            setToaster({
                                type: "danger",
                                title: "Error",
                                text: 'Error',
                                visiblity: "hide",
                            });
                            if (responsesuccess) {
                                localStorage.removeItem('data')
                                navigate('/mybookings')
                            }
                        }, 1500)
                        // getBookingsHandler()
                    })();
                },
                prefill: {
                    name: name,
                    email: email,
                    phone_number: phoneNumber,
                },
            };
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            console.log(error)
        }
    }
    return (<>
        <Header />
        <SideBar />
        <main class="main">
            <Toaster
                type={toaster.type}
                title={toaster.title}
                text={toaster.text}
                visiblity={toaster.visiblity}
            />
            <div class="site-breadcrumb" style={{ background: "url(assets/img/breadcrumb/01.jpg)" }}>
                <div class="container">
                    <h2 class="breadcrumb-title">Book A Ride</h2>
                    <ul class="breadcrumb-menu">
                        <li><a href="index.html">Home</a></li>
                        <li class="active">Book A Ride</li>
                    </ul>
                </div>
            </div>


            <div class="book-ride py-5">
                <div class="container">
                    <div class="row">

                        <div class="col-md-10 mx-auto">
                            <div class="booking-form">
                                <div class="book-ride-head">
                                    <h4 class="booking-title">Confirm your booking</h4>
                                    <p>It is a long established fact that a reader will be distracted by the readable
                                        content of a page when looking at its layout. The point of using is that it has
                                        distribution of letters to using content here making it look like readable.</p>
                                </div>
                                {bookingData?.tripType != 'sharing' ?
                                    <div class="col-md-12 col-lg-12 filter-item cat1 cat2">
                                        <div class="row align-items-center ">
                                            <a className="col-md-6 col-12"><div class="taxi-img text-center">
                                                <img src={constant.IMAGE_URL + (bookingData?.item.photos ? bookingData?.item.photos[0] : bookingData?.item.Car.photos[0])} crossorigin="anonymous" style={{height:'350px', width:'100%', objectFit: 'cover'}} />
                                            </div></a>
                                            <div class="taxi-content col-md-6 col-12">
                                                <div class="taxi-head">
                                                    <span> <b>Selected Car</b></span><h4>{bookingData?.item.Car ? bookingData?.item.Car.brand : bookingData?.item.brand} {bookingData?.item.Car ? bookingData?.item.Car.name : bookingData?.item.name}</h4>

                                                </div>
                                                <div class="taxi-feature">
                                                    <ul className="">
                                                        <li> Estimated KM: <span> <b>{bookingData?.tripType == 'twoway' ? (bookingData?.totalKm * 2)?.toFixed(0) : (bookingData?.totalKm)?.toFixed(0)} KM</b></span></li>
                                                        <li> Estimated Time: <span> <b>{bookingData?.duration} <small>(for oneway)</small></b></span></li>
                                                        <li> Estimated Fare: <span> <b>Rs. {bookingData?.tripType == 'twoway' ? ((perKmCost * bookingData?.totalKm) * 2)?.toFixed(0) : (perKmCost * bookingData?.totalKm)?.toFixed(0)}</b></span></li>
                                                        <li> Trip Type: <span> <b>{bookingData?.tripType}</b></span></li>

                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div> :
                                    <div class="col-md-12 col-lg-12 filter-item cat1 cat2">

                                        <div class="row align-items-center ">
                                            <a className="col-md-6 col-12"><div class="taxi-img text-center">
                                                <img src={constant.IMAGE_URL + bookingData?.item.carData.photos[0]} crossorigin="anonymous" width={'350px'} />
                                            </div></a>
                                            <div class="taxi-content col-md-6 col-12">
                                                <div class="taxi-head">
                                                    <span> <b>Selected Car</b></span><h4>{bookingData?.item.carData.brand} {bookingData?.item.carData.name}</h4>

                                                </div>
                                                <div class="taxi-feature">
                                                    <ul className="">
                                                        <li> Estimated KM: <span> <b>{bookingData?.item.distanceBreakdown.distance / 1000} KM</b></span></li>
                                                        
                                                        <li> Estimated Fare: <span> <b>Rs. {(((perKmCost * (bookingData.item.distanceBreakdown.distance / 1000)) / ((bookingData.item.carData.customerCapacity - bookingData.item.sharingAvailable) + (+bookingData.vacant))) * bookingData.vacant)?.toFixed(0)}</b></span></li>
                                                        <li> Trip Type: <span className="text-capitalize"> <b>{bookingData?.tripType}</b></span></li>

                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>}

                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Full Name</label>
                                            <input type="text" class="form-control" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                                            <i class="far fa-user"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Phone Number</label>
                                            <input type="text" class="form-control" placeholder="Your Phone" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                            <i class="far fa-phone"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input type="text" class="form-control" placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                            <i class="far fa-envelope"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Passenger Count</label>
                                            <input type="text" class="form-control" placeholder="Enter total passenger" value={passengerCount} onChange={(e) => { e.target.value <= (bookingData.item.Car ? bookingData.item.Car.customerCapacity : bookingData.item.customerCapacity) && setPassengerCount(e.target.value) }} />
                                            <i class="far fa-envelope"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Pick Up Location</label>
                                            <input type="text" class="form-control" placeholder="Type Location" disabled={true} value={bookingData?.selectedPickup?.properties?.full_address} />
                                            <i class="far fa-location-dot"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Drop Off Location</label>
                                            <input type="text" class="form-control" placeholder="Type Location" disabled={true} value={bookingData?.selectedDrop?.properties?.full_address} />
                                            <i class="far fa-location-dot"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 d-flex gap-3 align-items-center fs-5">
                                        <div class="form-group">
                                            <label for='isAcAvailable'>Ac Required</label>
                                            <input type="checkbox" id="iisAcAvailables" style={{transform: 'scale(1.5)',marginLeft: '10px'}} placeholder="Type Location"  value={isAcAvailable} onChange={(e)=>setisAcAvailable(e.target.checked)} />
                                           
                                        </div>
                                        <div class="form-group">
                                            <label for='isCarrierAvailable'>Carrier Required</label>
                                            <input type="checkbox" id="isCarrierAvailable" placeholder="Type Location" style={{transform: 'scale(1.5)',marginLeft: '10px'}}  value={isCarrierAvailable} onChange={(e)=>setisCarrierAvailable(e.target.checked)} />
                                           
                                        </div>
                                    </div>

                                    {bookingData?.tripType !== 'sharing' && <><div class={`col-lg-6`}>
                                        <div class="form-group">
                                            <label>Pick Up Date</label>
                                            <input type="datetime-local" class="form-control" placeholder="MM/DD/YY" disabled={true} value={bookingData?.pickupDate} />
                                            <i class="far fa-calendar-days"></i>
                                        </div>
                                    </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <label>Dropping Date</label>
                                                <input type="datetime-local" value={endDate} onChange={(e) => setEndDate(e.target.value)} class="form-control" placeholder="MM/DD/YY" />
                                                <i class="far fa-clock"></i>
                                            </div>
                                        </div></>}


                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label>Your Message</label>
                                            <textarea class="form-control" rows="5" value={customerNote} onChange={(e) => setCustomerNote(e.target.value)}
                                                placeholder="Write Your Message"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="condition" />
                                            <label class="form-check-label" for="condition">
                                                By using this form you agree to our terms & conditions.
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-10 col-12 mx-auto d-lg-flex d-block">
                                        <Link to={'/taxi'} class="theme-btn bg-secondary text-white mx-lg-4 mx-0" ><i
                                            class="fas fa-arrow-left"></i> Edit Details</Link>
                                      {bookingData?.item?.vendorId ?  <button onClick={() => { requestVendorBooking() }} class="theme-btn mx-lg-4 mx-0" >Confirm Booking <i
                                            class="fas fa-arrow-right"></i></button> :  <button onClick={() => { bookingData.tripType == 'sharing' ? requestPaymentHandler() : requestBookingHandler() }} class="theme-btn mx-lg-4 mx-0" >Book Your Taxi<i
                                            class="fas fa-arrow-right"></i></button>}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
        {/* <Footer /> */}
    </>
    )
}
export default BookNow