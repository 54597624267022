import { Link } from "react-router-dom"
import DashboardSideBar from "../../components/dashboardSidebar"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"
import services from "../../services"
import { useEffect, useState } from "react"
import moment from "moment"

import { isMobile } from 'react-device-detect';
import constant from "../../constant"
const Dashboard = () =>{
    const [bookingList, setBookingList] = useState([])
    const [pendingbooking, setpendingbooking] = useState([])
    const [completedbooking, setcompletedbooking] = useState([])
    const [upcomingbooking, setupcomingbooking] = useState([])
    useEffect(()=>{
        getBookingsHandler();
    },[])
    const getBookingsHandler = async() =>{
        try {
            const response = await services.order.GET_BOOKING();
            console.log(response)
            let data = response.data;
            if (data.length) {
                data.map((item) => {
                    item.BookingDetails.sort(function (a, b) {
                        return parseFloat(a.id) - parseFloat(b.id);
                    });
                })
                var newArray = data.filter(function (el) {
                    return el.BookingDetails[0].bookingStatus == 'confirmed' || el.BookingDetails[0].bookingStatus == 'accepted';
                  });
                var ended = data.filter(function (el) {
                    return el.BookingDetails[0].bookingStatus == 'ended';
                  });
                var pending = data.filter(function (el) {
                    return el.BookingDetails[0].bookingStatus == 'pending';
                  });
                  setupcomingbooking(newArray.length)
                  setcompletedbooking(ended.length)
                  setpendingbooking(pending.length)
                setBookingList(newArray)
            }
           
        } catch (error) {
            console.log(error)
        }
    }
    return (<>
    <Header/>
    <SideBar/>
        <main class="main">

       


        <div class="user-profile py-5">
            <div class="container">
                <div class="row">
                   <DashboardSideBar/>
                   <div class="col-lg-9">
                        <div class="user-profile-wrapper">
                            <div class="row">
                                <div class="col-md-6 col-lg-4">
                                    <div class="dashboard-widget dashboard-widget-color-1">
                                        <div class="dashboard-widget-info">
                                            <h1>{upcomingbooking}</h1>
                                            <span>Upcoming Booking</span>
                                        </div>
                                        <div class="dashboard-widget-icon">
                                            <i class="fal fa-list"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="dashboard-widget dashboard-widget-color-2">
                                        <div class="dashboard-widget-info">
                                            <h1>{completedbooking}</h1>
                                            <span>Completed Booking</span>
                                        </div>
                                        <div class="dashboard-widget-icon">
                                            <i class="fal fa-eye"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                    <div class="dashboard-widget dashboard-widget-color-3">
                                        <div class="dashboard-widget-info">
                                            <h1>{pendingbooking}</h1>
                                            <span>Pending Booking</span>
                                        </div>
                                        <div class="dashboard-widget-icon">
                                            <i class="fal fa-xmark-circle"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="user-profile-card">
                                        <h4 class="user-profile-card-title">Upcoming Booking</h4>
                                        {isMobile ? <div class="container my-3">

{bookingList?.map((item) => {
    return (<Link to={`/booking/${item.id}`} class="card booking-card">
        <div class="d-flex justify-content-between align-items-center">

            <div class="booking-info">
                <h6 className="mb-0">{item.Car.brand} {item.Car.name}</h6>
                <span>Booking ID: #{item.id}</span>
            </div>
            <img src={constant.IMAGE_URL + item?.VendorCar?.photos[0]} crossorigin="anonymous" class="rounded-circle" alt="Driver image" />
        </div>
        <div className="d-flex justify-content-between">
            <div class="mt-1">
                <span><strong>From : </strong>{item?.BookingDetails[0].pickupLocation.addressLine}</span> <br />
                <span><strong>to : </strong>{item?.BookingDetails[0].dropLocation.addressLine}</span>
            </div>
            <div class="mt-1">
                <span>{moment(item.tripStartDate).format('DD/MM/YY, hh:mm')}</span>
                <p>{moment(item.tripEndDate).format('DD/MM/YY, hh:mm')}</p>
            </div>
        </div>

        <div class="d-flex justify-content-between mt-2">
            <span class={`badge badge-status badge-${(item.bookingStatus == 'failed' || item.bookingStatus == 'cancelled' || item.bookingStatus == 'rejected') ? 'danger' : 'primary'} text-capitalize`}>{(item.bookingStatus == 'failed' || item.bookingStatus == 'cancelled' || item.bookingStatus == 'rejected') ? item.bookingStatus : item.BookingDetails[0].bookingStatus}</span>
            <strong>Rs. {((item.distanceBreakdown.distance / 1000) * (item.isAcAvailable ? item.VendorCar?.perKmCharge + 1 : item.VendorCar?.perKmCharge)).toFixed(0)}</strong>
        </div>
    </Link>)
})}



</div>
:
<div class="table-responsive">
    <table class="table text-nowrap">
        <thead>
            <tr>
                <th className="">Cab Info</th>
                <th>Journey Date</th>
                <th>Pickup and drop</th>
                <th>Price</th>
                <th>Status</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {bookingList?.map((item) => {
                return (<tr key={item.id}>
                    <td>
                        <div class="table-list-info">
                            <a href="#">
                                {/* <img src="/assets/img/taxi/01.png" alt/> */}
                                <div class="table-list-content">
                                    <h6>{item.Car.brand} {item.Car.name}</h6>
                                    <span>Booking ID: #{item.id}</span>
                                </div>
                            </a>
                        </div>
                    </td>
                    <td>
                        <span>{moment(item.tripStartDate).format('DD/MM/YY, hh:mm')}</span>
                        <p>{moment(item.tripEndDate).format('DD/MM/YY, hh:mm')}</p>
                    </td>
                    <td>
                        <span>{item?.BookingDetails[0].pickupLocation.addressLine}</span> <br />
                        <span>{item?.BookingDetails[0].dropLocation.addressLine}</span>
                        {/* <p>03:15 PM</p> */}
                    </td>
                    <td>Rs. {((item.distanceBreakdown.distance / 1000) * (item.isAcAvailable ? item.VendorCar?.perKmCharge + 1 : item.VendorCar?.perKmCharge)).toFixed(0)}</td>
                    <td><span class={`badge badge-${(item.bookingStatus == 'failed' || item.bookingStatus == 'cancelled' || item.bookingStatus == 'rejected') ? 'danger' : 'primary'} text-capitalize`}>{(item.bookingStatus == 'failed' || item.bookingStatus == 'cancelled' || item.bookingStatus == 'rejected') ? item.bookingStatus : item.BookingDetails[0].bookingStatus}</span></td>
                    <td>
                        <Link to={`/booking/${item.id}`}
                            class="btn btn-outline-secondary btn-sm rounded-2"
                            data-bs-toggle="tooltip" title="Details"><i
                                class="far fa-eye"></i></Link>
                    </td>
                </tr>)
            })}

        </tbody>
    </table>
</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </main>
    {/* <Footer/> */}
    </>
    )
}
export default Dashboard